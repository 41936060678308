import Vue from "vue";
import auth from './auth.services'
import users from './user.services'
import roles from './roles.services'
import profiles from './profiles.services'
import typeRoles from './typeRoles.services'
import paths from './path.services'
import authRRHH from './authRrhh.services'
import menu from "./menu.services";
import permisosEspeciales from "./permisosEspeciales";
import instituciones from "./instituciones.services";
import establecimientos from "./establecimientos";

Vue.prototype.services = {
    auth,
    users,
    roles,
    profiles,
    typeRoles,
    paths,
    authRRHH,
    menu,
    permisosEspeciales,
    instituciones,
    establecimientos,
}