import {http_client} from "@/plugins/http_client";

const getUsuarios = async (params = {}) => await http_client('/api/v1/users/roles-especiales', params)
const getUserRoles = async (id, params = {}) => await http_client(`/api/v1/users/roles-especiales/${id}`, params)
const createRolToUser = async (idUser, body) => await http_client(`/api/v1/users/roles-especiales/${idUser}`, body, 'post')
const deleteRoleUser = async (idUser, params = {}) => await http_client(`/api/v1/users/roles-especiales/${idUser}`, params, 'delete', {}, true, true)
const getEmpleado = async (id, params={}) => await http_client(`api/v1/users/empleado/${id}`, params)
export default {
    getUsuarios,
    getUserRoles,
    createRolToUser,
    deleteRoleUser,
    getEmpleado,
}