import {http_client} from "@/plugins/http_client";

const listar = async (filtros) => await http_client(`/api/v1/instituciones`, filtros ,'get');


//const listarRoles = async (filtros) => await http_client(`/api/v1/roles`, filtros ,'get');
//const listarPerfil = async (filtros) => await http_client(`/api/v1/perfiles`, filtros ,'get');
//const listarMenu = async (filtros) => await http_client(`/api/v1/menu`, filtros ,'get');
const listarModulo = async (filtros) => await http_client(`/api/v1/modulos`, filtros ,'get');
const informacionUsuario = async (filtros) => await http_client(`/api/v1/instituciones`, filtros ,'get');
// roles
const listarRoles = async (filtros) => await http_client(`/api/v1/roles`, filtros ,'get');
const crearRoles = async (body) => await http_client(`/api/v1/roles`, body ,'post');
const verRoles = async (id, filtros) => await http_client(`/api/v1/roles/${id}`, filtros ,'get');
const actualizarRoles = async (id, body) => await http_client(`/api/v1/roles/${id}`, body ,'put');
const eliminarRoles = async (id, filtros) => await http_client(`/api/v1/roles${id}`, filtros ,'delete');
// perfil
const listarPerfil = async (filtros) => await http_client(`/api/v1/perfiles`, filtros ,'get');
const crearPerfil = async (body) => await http_client(`/api/v1/perfiles`, body ,'post');
const verPerfil = async (id, filtros) => await http_client(`/api/v1/perfiles/${id}`, filtros ,'get');
const actualizarPerfil = async (id, body) => await http_client(`/api/v1/perfiles/${id}`, body ,'put');
const eliminarPerfil = async (id, filtros) => await http_client(`/api/v1/perfiles/${id}`, filtros ,'delete');
const modificarPerfil = async (id, body) => await http_client(`/api/v1/perfiles/${id}`, body ,'put');
//menu
const listarMenu = async (filtros) => await http_client(`/api/v1/menus`, filtros ,'get');
const crearMenu = async (body) => await http_client(`/api/v1/menus`, body ,'post');
const verMenu = async (id, filtros) => await http_client(`/api/v1/menus/${id}`, filtros ,'get');
const actualizarMenu = async (id, body) => await http_client(`/api/v1/menus/${id}`, body ,'put');
const eliminarMenu = async (id, filtros) => await http_client(`/api/v1/menus/${id}`, filtros ,'delete');
//modulo
const listarModulos = async (filtros) => await http_client(`/api/v1/modulos`, filtros ,'get');
const crearModulos = async (body) => await http_client(`/api/v1/modulos`, body ,'post');
const verModulos = async (id, filtros) => await http_client(`/api/v1/modulos/${id}`, filtros ,'get');
const actualizarModulos = async (id, body) => await http_client(`/api/v1/modulos/${id}`, body ,'put');
const eliminarModulos = async (id, filtros) => await http_client(`/api/v1/modulos/${id}`, filtros ,'delete');


const crear = async (body) => await http_client(`/api/v1/instituciones`, body ,'post');

const ver = async (id, filtros) => await http_client(`/api/v1/instituciones/${id}`, filtros ,'get');
const actualizar = async (id, body) => await http_client(`/api/v1/instituciones/${id}`, body ,'put');
const eliminar = async (id, filtros) => await http_client(`/api/v1/instituciones/${id}`, filtros ,'delete');
const verUsuario = async (documento) => await http_client(`/api/v1/users/${documento}`);

export default {
    listar,
    crear,
    ver,
    actualizar,
    eliminar,
    listarRoles,
    listarPerfil,
    listarMenu,
    listarModulo,
    informacionUsuario,
    crearRoles,
    verRoles,
    actualizarRoles,
    eliminarRoles,
    crearPerfil,
    verPerfil,
    actualizarPerfil,
    eliminarPerfil,
    modificarPerfil,
    crearMenu,
    verMenu,
    actualizarMenu,
    eliminarMenu,
    crearModulos,
    verModulos,
    actualizarModulos,
    eliminarModulos,
    listarModulos,
    verUsuario

}