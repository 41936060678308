<template>
  <v-app-bar app :elevation="0" color="bgWhite" prominent>
    <v-app-bar-nav-icon class="black--text mt-2" v-if="!sideBar" @click.stop="setSideBar(!sideBar)"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <!-- <v-row :style="$vuetify.breakpoint.smAndDown?'':'margin-left: 8em'"> -->
      <!-- <v-col class="col-8">
         <div class="white--text" style="margin-top: 10em">
          <p class="text-h4">
            Administrativo
          </p>
          <p>Administrador de RRHH</p>
        </div> 
      <v-text-field label="¿Que deseas buscar?"  dense
                      color="white" outlined dark/>
      </v-col> -->
          <div class="mt-5 d-flex align-center">
            <!-- <div :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-left: 1em;'" class="text-right mr-12"> -->
                <v-menu offset-y transition="scroll-y-transition" rounded="lg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" elevation="" text small class="mr-3">
                      <v-icon left color="btnAzul--text">mdi-menu-down-outline</v-icon>
                      <p class="mr-5 mt-4 font-weight-bold btnAzul--text">
                        {{ userInfo?.user?.email }}
                      </p>
                    </v-btn>
                  </template>
                  <v-list dense nav>
                    <v-list-item @click="cerrarSession()" class="my-0">
                      <v-list-item-icon>
                        <v-icon size="20">mdi-logout</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title >Cerrar sesión</v-list-item-title>
                    </v-list-item>
                  </v-list>
              </v-menu>
              <div class="circular-container" v-show="breakpoint()">
                <v-icon size="50" color="#ffff" >mdi-account-outline</v-icon>
              </div>
            <!-- </div> -->
<!--
        <v-menu offset-y transition="scroll-y-transition" rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on"  elevation="0" class="btn__hover">
              <div class="d-flex flex-column jus mr-3">
                <h5 class="primary&#45;&#45;text">{{ userInfo?.user?.email }}</h5>
                <h6 class="text-left icon__color">Adminstrativo</h6>
              </div>
              <div class="flex justify-center align-center">
                <v-avatar size="35" class="mr-2">
                  <img src="../assets/img/icon_people.png" alt="icon people" />
                </v-avatar>
                <v-icon class="icon__color">mdi-menu-down</v-icon>
              </div>
            </v-btn>
          </template>
          <v-list dense nav>
            <v-list-item :to="{name:'profile'}" class="my-0">
              <v-list-item-icon>
                <v-icon size="20">mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item>
            &lt;!&ndash; <v-list-item :to="{name:'security'}" class="my-0">
              <v-list-item-icon>
                <v-icon size="20">mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Seguridad</v-list-item-title>
            </v-list-item> &ndash;&gt;
            <v-list-item @click="cerrarSession()" class="my-0">
              <v-list-item-icon>
                <v-icon size="20">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Cerrar sesión</v-list-item-title>
            </v-list-item>
            &lt;!&ndash; <v-list-item class="my-0">
              <v-list-item-icon>
                <v-switch
                    v-model="$vuetify.theme.dark"
                    class="my-0"
                    hint="This toggles the global state of the Vuetify theme"
                    dense
                ></v-switch>
              </v-list-item-icon>
              <v-list-item-title>Tema Oscuro</v-list-item-title>
            </v-list-item> &ndash;&gt;
          </v-list>
        </v-menu>
-->
      </div>
    <!-- </v-row> -->
  </v-app-bar>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "AppHeader",
  methods: {
    ...mapMutations("utils", ["setSideBar", "setMenu", "setRoles"]),
    ...mapMutations(["setToken"]),
    async cerrarSession() {
      this.setMenu([]);
      this.setToken(null);
      this.setRoles([]);
      // await this.services.auth.logout()
      localStorage.clear();
      this.$router.push({name: 'login'}).catch((e) => {
      });
    },
    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return false;
      } else if (this.$vuetify.breakpoint.xs) {
        return false;
      } else if (this.$vuetify.breakpoint.md) {
        return true;
      } else if (this.$vuetify.breakpoint.lg) {
        return true;
      } else if (this.$vuetify.breakpoint.xl) {
        return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("utils", {
      sideBar: "sideBar",
    }),
  },
};
</script>

<style scoped lang="scss">
.btn__hover {
  &:hover {
    background-color: #f4f7fd !important;
  }
}
.icon__color {
  color: #697182 !important;
}

.circular-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1c1e4d;
  /* Fondo azul */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.circular-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
</style>
