import {http_client} from "@/plugins/http_client";

const getEstablecimientos = async (params = {}) => await http_client('/api/v1/instituciones/establecimiento', params)
//municipios y departamentos
const getDepartamentos = async (filtros) => await http_client('/api/v1/catalogos/departamentos', filtros, "get")
const getMunicipios = async (filtros = {}) => await http_client(`/api/v1/catalogos/municipios`, filtros, "get")
const getJornadas = async (filtros = {}) => await http_client(`/api/v1/catalogos/tipo-horario`, filtros, "get")
const getHorarios = async (filtros = {}) => await http_client(`/api/v1/jornadas`, filtros, "get")
// create, edit, delete
const createEstablecimiento = async (body) => await http_client(`/api/v1/instituciones/establecimiento`, body, 'post')
const editEstablecimiento = async (id, body) => await http_client(`/api/v1/instituciones/establecimiento/${id}`, body, 'put')
const deleteEstablecimiento = async (id) => await http_client(`/api/v1/instituciones/establecimiento/${id}`, {}, "delete");

export default {
    getEstablecimientos,
    getDepartamentos,
    getMunicipios,
    getJornadas,
    getHorarios,
    createEstablecimiento,
    editEstablecimiento,
    deleteEstablecimiento,
}