import { http_client } from "../plugins/http_client";

const url = "/api/v1/instituciones";

const getInstituciones = async (filtros) => await http_client(url, filtros);
const createInstitucion = async (body) => await http_client(url, body, "post");
const showInstitucion = async (id) => await http_client(`${url}/${id}`);
const updateInstitucion = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");
const deleteInstitucion = async (id) =>
  await http_client(`${url}/${id}`, {}, "delete");

export default {
  getInstituciones,
  createInstitucion,
  showInstitucion,
  updateInstitucion,
  deleteInstitucion,
};
