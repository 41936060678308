const routerFormacion = [
  {
    path: "/matenimientoRoles",
    name: "formacion",
    component: () =>
      import(/* webpackChunkName: "formacion" */ "./mantenimientoRoles.vue"),
  },
  {
    path: "/formulariocrearroles",
    name: "formularioCrearRoles",
    component: () =>
      import(
        /* webpackChunkName: "gestion-formacion" */ "./formularioCrearRoles.vue"
      ),
  },
  {
    path: "/matenimientoMenu",
    name: "matenimientoMenu",
    component: () =>
      import(
        /* webpackChunkName: "matenimientoMenu" */ "./mantenimientoMenu.vue"
      ),
  },
  {
    path: "/formularioCrearMenu",
    name: "formularioCrearMenu",
    component: () =>
      import(
        /* webpackChunkName: "gestion-formacion" */ "./formularioCrearMenu.vue"
      ),
  },

  {
    path: "/matenimientoModulos",
    name: "mantenimientoModulo",
    component: () =>
      import(/* webpackChunkName: "formacion" */ "./mantenimientoModulo.vue"),
  },
  {
    path: "/modulo/:id(\\d+)?/gestion",
    name: "formularioCrearModulo",
    component: () =>
      import(
        /* webpackChunkName: "gestion-formacion" */ "./formularioCrearModulo.vue"
      ),
  },

  {
    path: "/matenimientoPerfiles",
    name: "formacion_perfiles",
    component: () =>
      import(/* webpackChunkName: "formacion" */ "./mantenimientoPerfil.vue"),
  },
  {
    path: "/formacion/:id(\\d+)?/gestion",
    name: "formularioCrearPerfil",
    component: () =>
      import(
        /* webpackChunkName: "gestion-formacion" */ "./formularioCrearPerfil.vue"
      ),
  },
  {
    path: "/perfil",
    name: "perfilRRHH",
    component: () =>
      import(/* webpackChunkName: "formacion" */ "../formacion/perfilRRHH.vue"),
  },
  {
    path: "/mantenimiento-usuarios-carga-inicial",
    name: "mantenimiento-usuarios-carga-inicial",
    component: () =>
      import(
        /* webpackChunkName: "mantenimiento-usuarios-carga" */ "../../views/mantenimientos/usuarios/usuarios.vue"
      ),
  },
  {
    path: "/mantenimiento-usuarios-titular",
    name: "mantenimiento-usuarios-titular",
    component: () =>
      import(
        /* webpackChunkName: "mantenimiento-usuarios-titular" */ "../../views/mantenimientos/usuariosTitulares/usuarios_titulares.vue"
      ),
  },
  {
    path: "/mantenimiento-rutas",
    name: "mantenimiento-rutas",
    component: () =>
      import(
        /* webpackChunkName: "mantenimiento-rutas" */ "../../views/mantenimientos/rutas/rutas.vue"
      ),
  },
  {
    path: "/permisos-especiales",
    name: "permisos-especiales",
    component: () =>
      import(
        /* webpackChunkName: "permisos-especiales" */ "../../views/mantenimientos/permisosEspeciales/permisosEspeciales.vue"
      ),
  },
  //form permisos especuales con id
  {
    path: "/permisos-especiales/:id",
    name: "form-permisos-especiales",
    component: () =>
      import(
        /* webpackChunkName: "form-permisos-especiales" */ "../../views/mantenimientos/permisosEspeciales/formPermisosEspeciales.vue"
      ),
  },
  {
    path: "/instituciones",
    name: "institucionesRRHH",
    component: () =>
      import(
        /* webpackChunkName: "instituciones" */ "../../views/mantenimientos/mantenimientoInstitucion.vue"
      ),
  },
  {
    path: "/establecimientos",
    name: "establecimientos",
    component: () =>
      import(
        /* webpackChunkName: "establecimientos" */ "../../views/mantenimientos/mantenimientoEstablecimiento.vue"
      ),
  },
];

export default routerFormacion;
