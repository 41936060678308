<template>
    <v-main class="footer d-flex justify-center align-center px-2">
      <v-footer color="#fff">
        <v-row class="pa-sm-4  d-flex justify-center align-center">
          <v-col class="text-center" cols="12" md="3">
            <p class="d-flex justify-center mt-2">
              Secretaría de Innovación de la Presidencia
            </p>
          </v-col>
          <v-col class="pt-2 text-center d-flex justify-center align-center" cols="12" md="6">
            <v-img class="d-flex justify-center ma-3" contain height="80px" width="120px"
              src="@/assets/img/logo-gob.svg"></v-img>
          </v-col>
          <v-col class="py-4 text-center mt-2" cols="12" md="3">
            <p class="d-flex justify-center">Políticas de privacidad</p>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
  .footer {
    flex-shrink: 0;
    /* Prevent the footer from growing and pushing the content up */
    background-color: #fff;
    margin-right: auto;
  
  }
  </style>