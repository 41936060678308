import {http_client} from "@/plugins/http_client";

const getUsers = async (params = {}) => await http_client('/api/v1/users', params)
const createUser = async (body) => await http_client('/api/v1/users', body, 'post')
const showUser = async (idUser) => await http_client(`/api/v1/users/${idUser}`)
const updateUser = async (idUser, body) => await http_client(`/api/v1/users/${idUser}`, body, 'put')
const deleteUser = async (idUser) => await http_client(`/api/v1/users/titular/${idUser}`, {}, 'delete')
const deleteUserProfile = async (idUser, idProfile) => await http_client(`/api/v1/users/${idUser}/perfiles/${idProfile}`, {}, 'delete')
const createUserProfile = async (idUser, idProfile) => await http_client(`/api/v1/users/${idUser}/perfiles`, {
    id_perfil: idProfile
}, 'post')
const deleteUserRole = async (idUser, idRole) => await http_client(`/api/v1/users/${idUser}/roles/${idRole}`, {}, 'delete')
const createUserRole = async (idUser, idRole) => await http_client(`/api/v1/users/${idUser}/roles`, {
    id_rol: idRole
}, 'post')
//agregar y actualizar usuario
const newUser = async (body) => await http_client('/api/v1/users/initial', body, 'post')
const editUser = async (id,body) => await http_client(`/api/v1/users/initial/${id}`, body, 'put')
//Agregar y editar usuario titular
const newUserInitial = async (body) => await http_client('/api/v1/users/initial', body, 'post')
const editUserInitial = async (id,body) => await http_client(`/api/v1/users/initial/${id}`, body, 'put')
const getUsersInitial = async (params = {}) => await http_client('/api/v1/users/initial/index', params)
const deleteUserInitial = async (id) => await http_client(`/api/v1/users/initial/${id}`, {}, 'delete')
//Agregar y editar usuario titular
const newUserTitular = async (body) => await http_client('/api/v1/users/titular', body, 'post')
const editUserTitular = async (id,body) => await http_client(`/api/v1/users/titular/${id}`, body, 'put')
const getUsersTitular = async (params = {}) => await http_client('/api/v1/users/titular/index', params)
//Catálogos instituciones y establecimientos
const getTipoInstitucion = async (params = {}) => await http_client('/api/v1/catalogos/tipo-institucion', params)
const getTipoAcreedores = async (params = {}) => await http_client('/api/v1/catalogos/tipo-acreedores', params)
const getTipoEstablecimiento = async (params = {}) => await http_client('/api/v1/catalogos/tipo-establecimiento', params)
const getInstituciones = async (params = {}) => await http_client(`/api/v1/catalogos/instituciones`, params)
const getEstablecimientos = async (params = {}) => await http_client(`/api/v1/catalogos/establecimientos`, params)

export default {
    getUsers,
    createUser,
    showUser,
    updateUser,
    deleteUser,
    deleteUserProfile,
    createUserProfile,
    deleteUserRole,
    createUserRole,
    newUser,
    getTipoInstitucion,
    getTipoAcreedores,
    getTipoEstablecimiento,
    getInstituciones,
    getEstablecimientos,
    editUser,
    newUserTitular,
    editUserTitular,
    getUsersTitular,
    newUserInitial,
    editUserInitial,
    getUsersInitial,
    deleteUserInitial
}