const routerFormacion = [
  
  // {
  //   path: "/perfil",
  //   name: "perfilRRHH",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "formacion" */ "./perfilRRHH.vue" 
  //     ),
  // },
  {
    path: "/menu",
    name: "menu",
    component: () =>
      import(
        /* webpackChunkName: "menu" */ "./menu.vue" 
      ),
  },
  // {
  //   path: "/formacion/:id(\\d+)?/gestion",
  //   name: "gestion-formacion",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "gestion-formacion" */ "./formularioCrear.vue"
  //     ),
  // },

 
  
];

export default routerFormacion;
