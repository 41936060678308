<template>
    <v-row justify="center">
      <v-dialog
        v-model="value"
        content-class="elevation-0"
        :max-width="maxWidth"
        persistent
      >
        <v-container>
          <v-card
            height="auto"
            style="border-radius: 40px 40px 40px 40px"
            color="white"
          >
            <v-card-title
              v-if="showClose"
              :class="'text-h5 primary--text text-center' + (!$vuetify.breakpoint.xs ? ' justify-space-between' : ' justify-center')"
            >
              <span v-if="!$vuetify.breakpoint.xs"></span>
              <span style="color: #313945; font-weight: bold; font-size: 34px;">{{ title }}</span>
              <v-icon v-if="!$vuetify.breakpoint.xs" color="gray" @click="$emit('input', false)" class="mr-5">
                mdi-close
              </v-icon>
            </v-card-title>
  
            <v-card-title v-else class="text-h5 primary--text text-center">
              <section class="container">
                <div class="d-flex justify-center mt-8">
                  <span class="primary--text">{{ title }}</span>
                </div>
              </section>
            </v-card-title>
            <section class="container px-8">
              <slot name="content" />
            </section>
  
            <div class="actions-buttons pl-5 pb-5" :class="classButtons">
              <slot name="actions" />
            </div>
          </v-card>
        </v-container>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      showClose: {
        type: Boolean,
        default: true,
      },
      value: Boolean,
      maxWidth: {
        type: String,
        default: 600,
      },
      classButtons: {
        type: String,
        default: "d-flex justify-end",
      },
    },
  };
  </script>
  
  <style scoped>
  .actions-buttons button {
    margin-right: auto;
    margin-left: auto;
  }
  </style>
  