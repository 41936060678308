export default [
    {
        path: "/solicitudes",
       /* name: "solicitudes",*/ /*Se quitó este name para evitar un error en la consola"*/
        component: () => import(/* webpackChunkName: "solicitudesList" */ "./layout.vue"),
        children: [
            {
                path: "",
                name: "solicitud",
                component: () => import( /* webpackChunkName: "solicitud" */ "./views/Solicitud.vue" )
            },
            {
                path: "created",
                name: "solicitud-created",
                component: () => import( /* webpackChunkName: "solicitud" */ "./views/SolicitudCreated.vue" )
            }
        ]
    }
]